import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./quizanalytics.scss";
import { optionGenerateBarNormalChart } from "./CommonGraph/EchartOptionGenerate";
import ReactECharts from "echarts-for-react";
import CommonSelect from "./CommonSelect/CommonSelect";
import * as userActions from "../../redux/actions/userActions";
import { useSelector, useDispatch } from "react-redux";

const QuizAnalytics = () => {
  const [qUserData, setQUserData] = useState({});
  const [topicWiseQues, setTopicWiseQues] = useState({});
  const [selTopic, setSelTopic] = useState("");
  const [clientId, setClientId] = useState(-1);
  const [courseId, setCourseId] = useState("");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [tableHeight, setTableHeight] = useState(0);
  const [topics, setTopics] = useState({});
  const [topicsMap, setTopicsMap] = useState([]);
  const [quizzes, setQuizzes] = useState({});
  const [topicScore, setTopicScore] = useState(0);
  const [totalNumberQuestion, setTotalNumberQuestion] = useState(0);
  const [loaderType, setLoaderType] = useState(0);

  const eachUserGraphref = useRef(null);

  const clientsList = useSelector((state) => state.client.allClientList);
  const companyDataLoader = useSelector(
    (state) => state.user.companyDataLoader
  );
  const clientCoursesData = useSelector(
    (state) => state.user.clientCoursesData
  );
  const courseQuizData = useSelector((state) => state.user.courseQuizData);

  const dispatch = useDispatch();

  //   useEffect(() => {
  //     window.addEventListener("resize", updateTableHeight);

  //     return () => {
  //       window.removeEventListener("resize", updateTableHeight);
  //     };
  //   }, []);

  useEffect(() => {
    const courseqD = courseQuizData[`${clientId}:${courseId}`];

    const quizData =
      courseqD && Object.keys(courseqD?.crs || {}).length > 0 ? courseqD : {};
    if (Object.keys(quizData).length) {
      // reversing the number id mapping for topics
      const qtps = Object.entries(quizData.tpc).reduce((acc, tp) => {
        acc[tp[1]] = tp[0];
        return acc;
      }, {});
      // reversing the number id mapping for learning aids
      const qlrns = Object.entries(quizData.lrn).reduce((acc, lrn) => {
        acc[lrn[1]] = lrn[0];
        return acc;
      }, {});

      // combining all topics
      const tpcs = Object.keys(quizData.crs).reduce((acc, crs) => {
        return { ...acc, ...quizData.crs[crs] };
      }, {});

      // combining all the quizzes
      const qzs = Object.keys(tpcs).reduce((acc, tp) => {
        return { ...acc, ...tpcs[tp] };
      }, {});

      // evaluating total number of question in course
      const tNQuest = Object.keys(qzs).reduce(
        (acc, qz) => acc + (qzs?.[qz]?.quiz?.length || 0),
        0
      );
      setTopics(tpcs);
      setQuizzes(qzs);
      setTotalNumberQuestion(tNQuest);

      if (Object.keys(qtps).length > 0) {
        // getting topic wise question stats
        const tpWs = topicWise(quizData, qtps, qlrns, tpcs);

        // selectic first topic after course slection
        const selTp = Object?.keys(tpWs)?.[0] || "";
        setSelTopic(selTp);
        setTopicScore(calculateTopicScore(tpWs, selTp));

        const topicNames = Object.keys(tpWs).map((key, index) => {
          const { topic_map } = courseQuizData[`${clientId}:${courseId}`];
          return { id: key, name: topic_map?.[qtps?.[key]] || key };
        });
        setTopicsMap(topicNames);
        setTopicWiseQues(tpWs);
      }

      setQUserData(dataForUserTotAttCorr(quizData));
    } else {
      setQUserData({});
      setTopics({});
      setQuizzes({});
      setTopicsMap([]);
      setSelTopic("");
      setTopicWiseQues({});
      setTotalNumberQuestion(0);
    }
  }, [courseId, courseQuizData]);

  useEffect(() => {
    if (clientId > 0 && !clientCoursesData[clientId]) {
      setLoaderType(1);
      dispatch(userActions.setCompanyLoader(true));
      dispatch(
        userActions.selectedClientCoursesDataRequest({ clientId: clientId })
      );
    }
  }, [clientId]);

  // get topic success score(i.e. (summation of correct attempts of all question) / (summation of total attempts of all question) )
  const calculateTopicScore = (tpWs, selTp) => {
    if (tpWs?.[selTp]) {
      const [totalAttempts, correctAttempts] = Object.values(tpWs[selTp])
        .reduce(
          (acc, quest) => [acc[0] + quest.tot, acc[1] + quest.corr],
          [0, 0]
        );
      return totalAttempts > 0 ? Math.floor((correctAttempts / totalAttempts) * 100) : 0;
    }
    return 0;
  };
  
  const returnNoDataText = () => {
    let text = "";
    if (clientId < 0) {
      text = "Please select a client";
    }
    else if (
      !clientCoursesData?.[clientId]?.courses ||
      Object.keys(clientCoursesData?.[clientId]?.courses).length === 0
    ) {
      text = "No courses available!";
    } else if (!courseId?.length) {
      text = "Please select a course";
    } else if (
      Object.keys(courseQuizData?.[`${clientId}:${courseId}`]?.crs || {})
        .length === 0 || Object.keys(topicWiseQues).length === 0
    ) {
      text = "No quizzes are available in the topics for this course!";
    }

    return text.length ? (
      <p className="fs-4 fw-bold my-5 py-5">{text}</p>
    ) : (
      <></>
    );
  };

  const topicWise = (quizData, qTopics, qLrnIds, topics) => {
    const topicQuestions = {};
  
    // Helper function to sort answers
    const cleanAndSortAnswers = (answers) => {
      return answers.split(":").map((ans) =>
        ans
          .split("")
          .sort((a, b) => a - b) 
          .join("")
      );
    };
  
    
    for (let tp in topics) {
      
      for (let qz in topics[tp]) {
        const tpqzMap = `${qTopics[tp]}:${qLrnIds[qz]}`; //  key for this quiz similar to given in courseQuizData[user] 
        const { answer, qtype, quiz: quizLrn } = topics[tp][qz];
        const answers = cleanAndSortAnswers(answer);
        const qtypes = qtype.split(":"); 
  
        // Initialize the questions object for this particular quiz
        const questions = quizLrn.reduce((acc, qzs, index) => {
          acc[`${tpqzMap}:${index}`] = {
            question: qzs.question, // Store the question text
            tot: 0, // Initialize total attempts (how many users attempted this question)
            corr: 0, // Initialize correct answers (how many of the attempts were correct)
            wrong: 0, // Initialize wrong answers (how many of the attempts were incorrect)
          };
          return acc;
        }, {});
  
        for (let usr in quizData.user) {
          const userQuizData = quizData.user[usr]; // Get the user's quiz data
  
          if (tpqzMap in userQuizData) { // Check if this user has attempted this question
            const { userstr } = userQuizData[tpqzMap]; // Get the user's answer string
  
            if (userstr) {
              
              let [userAnswerStr, , orderStr] = userstr.split("_"); // if array length 3 after split than question may be randomized otherewise no randomization
              let userAnswers = cleanAndSortAnswers(userAnswerStr);
  
              // If question were randomized than make the order of user answers same as order of correct answers
              if (orderStr) {
                const order = orderStr.split(":").map(Number);
                userAnswers = order.map((i) => userAnswers[i]);
              }
  
              // checking each question's answer with user answers according to question type
              userAnswers.forEach((userAnswer, i) => {
                
                const isCorrect =
                  qtypes[i] !== "0"
                    ? userAnswer === answers[i]
                    : +userAnswer === Math.max(+answers[i] - 1, 0); // this is for true and false question
  
                const key = `${tpqzMap}:${i}`; // Generate a unique key for this question
                 if(questions[key]){
                  questions[key].tot = (questions[key]?.tot || 0) + 1;// Increment the total attempts
                  questions[key].corr = (questions[key]?.corr || 0) + isCorrect;// Increment correct count if the answer is correct
                  questions[key].wrong = (questions[key]?.wrong || 0) + !isCorrect; // Increment wrong count if the answer is incorrect
                }
              });
            }
          }
        }
  
        // Merge the results for this quiz into the topic's results
        if (topicQuestions[tp]) {
          topicQuestions[tp] = { ...topicQuestions[tp], ...questions }; // Merge questions if topic already exists
        } else {
          topicQuestions[tp] = questions; // Initialize the questions object if it's the first time for this topic
        }
      }
    }
  
  // topicQuestions contains key as each topic and in each topic contains all the question in that topic 
    return topicQuestions; 
  };
  
  
/**
 * Calculates the total number of attempted and correctly answered questions for each user.
 * 
 * @param {Object} data - The data object containing quiz information for multiple users.
 * 
 * @returns {Object} users - An object where each key is a user identifier and the value is an object containing:
 *   - `totAttempted` (number): The total number of questions attempted by the user.
 *   - `corrAnswered` (number): The total number of correct answers by the user.
 * // Output:
 * // {
 * //   user1: { totAttempted: 8, corrAnswered: 1 },
 * //   user2: { totAttempted: 3, corrAnswered: 1 },
 * // }
 */
  const dataForUserTotAttCorr = (data) => {
    const users = {};
    for (let user in data.user) {
      const userObj = { totAttempted: 0, corrAnswered: 0 };

      for (let val of Object.values(data.user[user])) {
        const { userstr } = val;
        if (userstr) {
          const attemptedQuestions = userstr.split("_")[0].split(":");
          const correctlyAnswered = attemptedQuestions.pop();

          // userObj["tot"] += attemptedQuestions.length;
          userObj["totAttempted"] += attemptedQuestions.length;
          userObj["corrAnswered"] += +correctlyAnswered;
        }
      }
      users[user] = userObj;
    }

    return users;
  };


  const onTopicSelectChange = (e) => {
    setSelTopic(e.target.value);
    setTopicScore(calculateTopicScore(topicWiseQues, e.target.value));
  };

  const onClietSelectChange = (e) => {
    const [clientid, clientname] = e.target?.value?.split("-");
    setClientId(Number(clientid));
    setCourseId("");
    setSelTopic("");
    setLoaderType(0);
  };
  const sortClientList = (list) => {
    const sortedList = [...list]?.sort((a, b) =>
      a.clientname.localeCompare(b.clientname)
    );
    return sortedList;
  };

  const onCourseSelChange = (e) => {
    setTopicsMap([]);
    setCourseId(e.target.value);
    setLoaderType(2);
    setSelTopic("");
    if (!courseQuizData[`${clientId}:${e.target.value}`]) {
      const data = {
        clientid: clientId,
        courseid: +e.target.value,
      };
      dispatch(userActions.setCompanyLoader(true));
      dispatch(userActions.courseQuizDataReq(data));
    }
  };
  const updateTableHeight = () => {
    if (eachUserGraphref.current) {
      setTableHeight(eachUserGraphref.current.offsetHeight);
    }
  };

  // Update height on mount

  const onCollapseChange = (e) => {
    setIsCollapsed(!isCollapsed);
    if (!isCollapsed) {
      updateTableHeight();
    } else {
      setTableHeight(0);
    }
  };

  return (
    <div className="quiz-analytics-container">
      <div className={`tag-header`}>
        <h2 className="heading-text">Quiz Analysis</h2>
      </div>
      <Box className="tag-box">
        <div>
          <div className="d-flex gap-5 my-3 flex-wrap">
            {clientsList && (
              <CommonSelect
                onChange={onClietSelectChange}
                data={sortClientList(clientsList)}
                first=""
                flag={true}
                selected={clientId}
              />
            )}
            {clientCoursesData?.[clientId]?.courses &&
            Object.keys(clientCoursesData?.[clientId]?.courses)?.length > 0 ? (
              <CommonSelect
                onChange={onCourseSelChange}
                data={Object.entries(clientCoursesData[clientId].courses).sort((a, b) => a[1].localeCompare(b[1])).map(
                  ([key, value], index) => {
                    return { id: key, name: value };
                  }
                )}
                first=""
                flag={false}
                selected={courseId}
                text="Courses"
              />
            ) : companyDataLoader && loaderType === 1 ? (
              <div className="loader-wrapper">
                <div className="loader-cnt">
                  <i class="fa fa-spinner" aria-hidden="true"></i>
                  Loading
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          {Object.keys(topicWiseQues).length > 0 ? (
            <div className="quiz-analysis">
              <CommonSelect
                onChange={onTopicSelectChange}
                data={topicsMap}
                first=""
                flag={false}
                selected={selTopic}
                text="Topics"
              />
              <div className="quiz-each-user-graph mt-4">
                <div
                  className="graph-header p-3 d-flex justify-content-between align-items-center gap-4"
                  style={{
                    borderBottom: isCollapsed ? "none" : "1px solid #b5b5b5;",
                  }}
                >
                  <h4 className="fs-5 fw-bold mb-0">
                    Quiz performance for each user in the course
                  </h4>
                  <button
                    onClick={onCollapseChange}
                    className="border-0 bg-transparent fw-bold"
                    style={{ color: "#0178e6" }}
                  >
                    {isCollapsed ? "Expand" : "Collapse"}
                  </button>
                </div>
                <div
                  className="graph-wrapper"
                  style={{
                    height: `${
                      !isCollapsed
                        ? eachUserGraphref.current?.offsetHeight
                          ? eachUserGraphref.current.offsetHeight + "px"
                          : "auto"
                        : "0px"
                    }`,
                    transition: "height 0.5s ease-in-out",
                  }}
                >
                  <div
                    className="graph-collapse pt-3"
                    style={{ top: `-${tableHeight}px` }}
                    ref={eachUserGraphref}
                  >
                    <GraphContainer
                      options={{
                        legend: {
                          data: ["Answered Wrong", "Answered Correctly"],
                        },
                        dataset: {
                          dimensions: [
                            "Users",
                            "Answered Wrong",
                            "Answered Correctly",
                          ],
                          source: Object.entries(qUserData).map((user) => {
                            const answeredWrong = user[1].totAttempted - user[1].corrAnswered;
                            const answeredCorrectly = user[1].corrAnswered;
                            const result = { Users:  clientCoursesData?.[clientId]?.users?.[user[0]]
                              ?.name || user[0] };
                              console.log(answeredWrong,answeredCorrectly)
                            if (answeredWrong > 0) result["Answered Wrong"] = answeredWrong;
                            if (answeredCorrectly > 0) result["Answered Correctly"] = answeredCorrectly;
                        
                            return result;
                            // return {
                            //   Users:
                                // clientCoursesData?.[clientId]?.users?.[user[0]]
                                //   ?.name || user[0],
                            //   "Answered Wrong":
                            //     user[1].totAttempted - user[1].corrAnswered,
                            //   "Answered Correctly": user[1].corrAnswered,
                            // };
                          }),
                        },
                        xAxis: { type: "category" },
                        yAxis: { max: totalNumberQuestion },
                        // Declare several bar series, each will be mapped
                        // to a column of dataset.source by default.
                        series: [
                          {
                            type: "bar",
                            stack: "a",
                            label: true,
                            itemStyle: {
                              color: "#ee6666",
                            },
                          },
                          {
                            type: "bar",
                            stack: "a",
                            label: true,
                            itemStyle: {
                              color: "#91cc75",
                            },
                          },
                        ],
                      }}
                      echartOptions={optionGenerateBarNormalChart}
                      data={qUserData}
                    />
                  </div>
                </div>
              </div>
              <div className="quiz-table-wrapper">
                <div className="table-header">
                  <h3 className=" fs-5 fw-bold mt-3 pt-2">
                    {topicsMap.find((item) => item.id === selTopic)?.name} -{" "}
                    {topicScore}%
                  </h3>
                </div>

                <div className="table-quiz-analysis">
                  <table>
                    <thead>
                      <tr>
                        <th className="col-1">Question</th>
                        <th className="col-2">Stats</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(topicWiseQues?.[selTopic] || {}).map(
                        ([questId, quest], index) => {
                          const tot = quest.tot;
                          const cor = quest.corr;
                          return (
                            <tr key={questId}>
                              <td className="col-1">{quest.question}</td>
                              <td className="col-2">
                                <div className="d-flex gap-4 align-items-center">
                                  <div className="quiz-stat-container">
                                    <div className="quiz-stat-cor-wr">
                                      <div
                                        className="correct st-bar"
                                        style={{
                                          width: (cor / tot) * 100 + "%",
                                        }}
                                      ></div>
                                      <div
                                        className="wrong st-bar"
                                        style={{
                                          width:
                                            ((tot - cor) / tot) * 100 + "%",
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                  <div className="about-stats">
                                    <div className="d-flex gap-2 align-items-center">
                                      <div
                                        className="point"
                                        style={{ backgroundColor: "#91cc75" }}
                                      ></div>
                                      <div>Correct: {cor}</div>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div
                                        className="point"
                                        style={{ backgroundColor: "#ee6666" }}
                                      ></div>
                                      <div>Wrong: {tot - cor}</div>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div
                                        className="point"
                                        style={{ backgroundColor: "#5470c6" }}
                                      ></div>
                                      <div>Total: {tot}</div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center d-flex justify-content-center">
              {companyDataLoader ? (
                loaderType === 2 ? (
                  <div className="loader-wrapper topic-loader py-5">
                    <div className="loader-cnt">
                      <i class="fa fa-spinner" aria-hidden="true"></i>
                    </div>
                  </div>
                ) : (
                  <></>
                )
              ) : (
                returnNoDataText()
              )}
            </div>
          )}
        </div>
      </Box>
    </div>
  );
};

export default QuizAnalytics;

const GraphContainer = ({ options, echartOptions, data }) => {

  return (
    <div className="graph-container-bar">
      <ReactECharts
        style={{ height: "500px" || "100%", width: "100%" }}
        option={echartOptions(options || {}, data)}
      />
    </div>
  );
};
